
import Vue from 'vue';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { EditDriverMonthlyAttendanceEvent } from '~/framework/events/eventManager';
import { CssClasses, Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { SystemContext } from '~/framework/systemContext';
import {
  DriverMonthlyAttendances,
  IDriverMonthlyAttendances,
} from '~/components/common/r-driver-monthly-attendances-dialog/driverMonthlyAttendances';
import { IHeaderDayColumn } from '~/components/common/r-driver-monthly-attendances-dialog/headerDayColumn';
import { hhMmToSecs, secsToHhMm } from '~/framework/services/date-time/date-time';
import {
  generateCarItems,
  generateHolidayDriverAttendanceTemplateItems,
  generateWorkDayDriverAttendanceTemplateItems,
  ICarItem,
  IDriverAttendanceTemplateItem,
} from '~/components/common/r-driver-monthly-attendances-dialog/r-driver-attendance/selectionItems';
import { MonthDatesCalendar } from '~/framework/services/calendar/monthDatesCalendar';
import {
  overtimeWorkTypeItems,
  hasRestPeriodItems,
  IHasRestPeriodItem,
  IOvertimeWorkTypeItem,
} from '~/framework/view-models/driverAttendance';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';

import RDriverAttendance from '~/components/common/r-driver-monthly-attendances-dialog/r-driver-attendance/RDriverAttendance.vue';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { AggregatedCarEntity as ICarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import {
  AggregatedDriverEntity,
  AggregatedDriverEntity as IDriverEntity,
} from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import {
  driverAttendanceSymbol,
  DriverAttendanceApplicationService,
} from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';
import { mapEntity } from '~/framework/core/mapper';
import { IDriverAttendanceError } from '~/components/common/r-driver-monthly-attendances-dialog/driverAttendanceError';
import {
  AdditionalInfoKeys,
  FormTargetParams,
  PageNames,
  RinEventDialogComponentParam,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { holidayRuleSymbol } from '~/framework/application/masters/holiday-rule/holidayRuleApplicationService';
import { userSettingSymbol } from '~/framework/application/userSettingApplicationService';
import { driverAttendanceTemplateSymbol } from '~/framework/application/masters/driver-attendance-template/driverAttendanceTemplateApplicationService';
import { carSymbol } from '~/framework/application/masters/car/carApplicationService';
import { IDriverAttendanceUpdateData } from '~/framework/server-api/masters/driverAttendance';
import {
  AttendanceApplicationService,
  attendanceSymbol,
} from '~/framework/application/masters/attendance/attendanceApplicationService';

type DataType = {
  viewModel: IDriverMonthlyAttendances;
  isActive: boolean;
  isRemoveConfirmDialogActive: boolean;
  isAutocompleteConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  attendanceApplicationService: AttendanceApplicationService;
  driverAttendanceApplicationService: DriverAttendanceApplicationService;
  driverAttendanceTemplates: DriverAttendanceTemplateEntity[];
  driverAttendanceTemplateMap: Map<PersistentId, DriverAttendanceTemplateEntity>;
  cars: ICarEntity[];
  overtimeWorkTypeItems: IOvertimeWorkTypeItem[];
  workDayDriverAttendanceTemplateItems: IDriverAttendanceTemplateItem[];
  holidayDriverAttendanceTemplateItems: IDriverAttendanceTemplateItem[];
  carItems: ICarItem[];
  hasRestPeriodItems: IHasRestPeriodItem[];
  isManipulatingDriverAttendances: boolean;
};

type UpdatableFields = keyof IDriverAttendanceUpdateData;
type UpdateField<Field extends UpdatableFields> = {
  field: Field;
  value: IDriverAttendanceUpdateData[Field];
};

enum EventTypes {
  Change = 'change',
}

const autocompleteSnackbarMessage = '車番が他の乗務員と重複している日付は\n自動で勤怠を入力できませんでした。';
const noPrimaryCarSnackbarMessage = 'よく乗る車が設定されいないため\n自動で勤怠を入力できませんでした。';
const noTemplateIdSnackbarMessage = '勤怠時間が設定されいないため\n自動で勤怠を入力できませんでした。';

export default Vue.extend({
  name: 'RDriverMonthlyAttendancesDialog',
  components: {
    RDriverAttendance,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const attendanceApplicationService = this.$context.applications.get(attendanceSymbol);
    const driverAttendanceApplicationService = this.$context.applications.get(driverAttendanceSymbol);
    return {
      isActive: false,
      isRemoveConfirmDialogActive: false,
      isAutocompleteConfirmDialogActive: false,
      viewModel: undefined as unknown as IDriverMonthlyAttendances,
      driverAttendanceTemplates: undefined as unknown as DriverAttendanceTemplateEntity[],
      driverAttendanceTemplateMap: undefined as unknown as Map<PersistentId, DriverAttendanceTemplateEntity>,
      cars: undefined as unknown as ICarEntity[],
      overtimeWorkTypeItems,
      workDayDriverAttendanceTemplateItems: undefined as unknown as IDriverAttendanceTemplateItem[],
      holidayDriverAttendanceTemplateItems: undefined as unknown as IDriverAttendanceTemplateItem[],
      carItems: undefined as unknown as ICarItem[],
      isManipulatingDriverAttendances: false,
      listenerDisposer: undefined,
      hasRestPeriodItems,
      attendanceApplicationService,
      driverAttendanceApplicationService,
    };
  },
  watch: {
    driverAttendanceTemplates(value: DriverAttendanceTemplateEntity[]) {
      this.workDayDriverAttendanceTemplateItems = generateWorkDayDriverAttendanceTemplateItems(value);
      this.holidayDriverAttendanceTemplateItems = generateHolidayDriverAttendanceTemplateItems(value);
    },
    cars(value: ICarEntity[]) {
      this.carItems = generateCarItems(value);
    },
  },
  mounted() {
    const disposer = this.$context.events.openDriverMonthlyAttendancesFormEvent.on(this.onOpenForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      disposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    showSelectedWeek() {
      for (const week of this.$refs.weeks as HTMLDivElement[]) {
        if (week.classList.contains('selected')) {
          week.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
      }
    },
    secsToHhMm,
    /**
     * @public
     */
    async open(
      driver: IDriverEntity,
      baseDate: Date,
      driverAttendances: Maybe<AggregatedDriverAttendanceEntity[]>,
      errors: Maybe<IDriverAttendanceError[]>
    ): Promise<void> {
      if (this.isActive) return;
      this.viewModel = await this.loadAsyncData(
        this.$nuxt.context.app.$context,
        driver,
        baseDate,
        driverAttendances,
        errors
      );
      await this.fetchDriverAttendances();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
      await this.$nextTick();
      this.showSelectedWeek();
    },

    async onOpenForm(event: EditDriverMonthlyAttendanceEvent): Promise<void> {
      await this.open(event.driver, event.baseDate, event.driverAttendances, event.errors);
    },

    async loadAsyncData(
      _context: SystemContext,
      driver: IDriverEntity,
      baseDate: Date,
      prefetchedDriverAttendances: Maybe<AggregatedDriverAttendanceEntity[]>,
      errors: Maybe<IDriverAttendanceError[]>
    ): Promise<IDriverMonthlyAttendances> {
      const driverAttendanceTemplateApplicationService = this.$context.applications.get(driverAttendanceTemplateSymbol);
      const carApplicationService = this.$context.applications.get(carSymbol);
      const holidayRuleApplicationService = this.$context.applications.get(holidayRuleSymbol);
      const userSettingApplicationService = this.$context.applications.get(userSettingSymbol);
      const [driverAttendanceTemplates, cars, holidayRule, userSetting] = await Promise.all([
        driverAttendanceTemplateApplicationService.getAll(),
        carApplicationService.getAll(),
        holidayRuleApplicationService.get(),
        userSettingApplicationService.get(),
      ]);
      const monthDatesCalendar = new MonthDatesCalendar(holidayRule, baseDate, userSetting.scheduleStartOfWeek);

      this.driverAttendanceTemplates = driverAttendanceTemplates;
      this.driverAttendanceTemplateMap = mapEntity(driverAttendanceTemplates);
      this.cars = cars;

      return new DriverMonthlyAttendances(
        holidayRule,
        monthDatesCalendar,
        driver,
        baseDate,
        driverAttendanceTemplates,
        cars,
        prefetchedDriverAttendances ? [...prefetchedDriverAttendances] : [],
        new NationalHolidayService(),
        errors
      );
    },

    onCloseButtonClicked(): void {
      this.$rinGtm.push(RinEventNames.CLOSE_FORM, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE,
      });
      this.isActive = false;
      this.$context.events.closeDriverMonthlyAttendancesFormEvent.emit({ driver: this.viewModel.driver });
    },

    onClickAutocompleteButton(): void {
      this.isAutocompleteConfirmDialogActive = true;
    },

    async onCloseAutocompleteConfirmDialog(value: boolean): Promise<void> {
      this.isAutocompleteConfirmDialogActive = false;
      if (value) {
        this.$rinGtm.push(RinEventNames.AUTO_FILL, {
          [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE,
        });
        await this.autocomplete();
      }
    },

    async autocomplete(): Promise<void> {
      if (this.viewModel.driver.defaultPrimaryCar === undefined) {
        this.$context.snackbar.error(noPrimaryCarSnackbarMessage);
        return;
      }
      if (this.viewModel.driver.defaultAttendanceTemplate === undefined) {
        this.$context.snackbar.error(noTemplateIdSnackbarMessage);
        return;
      }
      this.isManipulatingDriverAttendances = true;
      const start = this.viewModel.monthDatesCalendar.firstDateOfMonth;
      const end = this.viewModel.monthDatesCalendar.lastDateOfMonth;
      const service = this.$context.applications.get(driverAttendanceSymbol);
      const driverAttendances = await service.createDefaultDriverAttendances(
        this.viewModel.driver.persistentId,
        start,
        end
      );
      const conflictedDates = this.viewModel.getConflictedDefaultDriverAttendanceDates(start, end, driverAttendances);
      this.viewModel.setDatesNotGenerated(conflictedDates);
      this.viewModel.addDriverAttendances(...driverAttendances);
      if (0 < conflictedDates.length) this.$context.snackbar.error(autocompleteSnackbarMessage);

      this.isManipulatingDriverAttendances = false;
    },

    async onClickPrevMonthButton(): Promise<void> {
      this.$rinGtm.push(RinEventNames.CHANGE_MONTH, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE,
      });
      this.viewModel.onClickPrevMonthButton();
      await this.fetchDriverAttendances();
    },

    async onClickNextMonthButton(): Promise<void> {
      this.$rinGtm.push(RinEventNames.CHANGE_MONTH, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE,
      });
      this.viewModel.onClickNextMonthButton();
      await this.fetchDriverAttendances();
    },

    async fetchDriverAttendances(): Promise<void> {
      const start = this.viewModel.monthDatesCalendar.firstDate;
      const end = this.viewModel.monthDatesCalendar.lastDate;
      const driverAttendances = await this.driverAttendanceApplicationService.getListOfDriver(
        this.viewModel.driver.persistentId,
        start,
        end
      );
      this.viewModel.addDriverAttendances(...driverAttendances);
    },

    async onCreateDriverAttendance(
      date: Date,
      driver: IDriverEntity,
      attendanceTemplateId: PersistentId
    ): Promise<void> {
      const templateMap = mapEntity(this.driverAttendanceTemplates);
      const template = templateMap.getOrError(attendanceTemplateId);
      this.isManipulatingDriverAttendances = true;
      const service = this.$context.applications.get(driverAttendanceSymbol);
      const attendance = await this.attendanceApplicationService.getOrCreate(date);
      const driverAttendance = await service.create(date, {
        attendanceId: attendance.id,
        driverId: driver.persistentId,
        templateName: template.name,
        primaryCarId: this.viewModel.getPreferablePrimaryCarId(date),
        forceRidePrimaryCar: false,
        regularWorkPeriodStart: template.regularWorkPeriodStart,
        regularWorkPeriodEnd: template.regularWorkPeriodEnd,
        restPeriodStart: template.restPeriodStart,
        restPeriodEnd: template.restPeriodEnd,
        overtimeWorkType: template.overtimeWorkType,
        overtimeWorkableDuration: template.overtimeWorkableDuration,
      });
      this.isManipulatingDriverAttendances = false;

      this.viewModel.addDriverAttendances(driverAttendance);
    },

    async onRemoveDriverAttendance(driverAttendance: AggregatedDriverAttendanceEntity): Promise<void> {
      await this.removeDriverAttendances(driverAttendance);
    },

    onClickRemoveButton(): void {
      this.isRemoveConfirmDialogActive = true;
    },

    async onCloseRemoveConfirmDialog(value: boolean): Promise<void> {
      this.isRemoveConfirmDialogActive = false;
      if (value) {
        this.$rinGtm.push(RinEventNames.DELETE_ALL, {
          [AdditionalInfoKeys.TARGET]: FormTargetParams.DRIVER_ATTENDANCE,
        });
        await this.removeDriverAttendancesOfSelectedMonth();
      }
    },

    async removeDriverAttendancesOfSelectedMonth(): Promise<void> {
      const driverAttendances = this.viewModel.getDriverAttendancesOfSelectedMonth();
      await this.removeDriverAttendances(...driverAttendances);
    },

    async removeDriverAttendances(...driverAttendances: AggregatedDriverAttendanceEntity[]): Promise<void> {
      const service = this.$context.applications.get(driverAttendanceSymbol);
      this.isManipulatingDriverAttendances = true;
      await service.deleteDriverAttendances(driverAttendances.map((driverAttendance) => driverAttendance.persistentId));
      this.isManipulatingDriverAttendances = false;
      this.viewModel.onDeleteDriverAttendances(...driverAttendances);
    },

    async onChangeDriverAttendanceTemplateId(
      driverAttendance: AggregatedDriverAttendanceEntity,
      templateId: PersistentId
    ): Promise<void> {
      const template = this.driverAttendanceTemplateMap.getOrError(templateId);
      const updateFields: UpdateField<UpdatableFields>[] = [];
      updateFields.push({ field: 'templateName', value: template.name });
      updateFields.push({ field: 'regularWorkPeriodStart', value: template.regularWorkPeriodStart });
      updateFields.push({ field: 'regularWorkPeriodEnd', value: template.regularWorkPeriodEnd });
      updateFields.push({ field: 'restPeriodStart', value: template.restPeriodStart });
      updateFields.push({ field: 'restPeriodEnd', value: template.restPeriodEnd });
      updateFields.push({ field: 'overtimeWorkType', value: template.overtimeWorkType });
      updateFields.push({ field: 'overtimeWorkableDuration', value: template.overtimeWorkableDuration });
      const entity = await this.updateDriverAttendance(driverAttendance, updateFields);
      this.viewModel.onUpdateDriverAttendance(entity);
    },

    async onChangePrimaryCarId(
      driverAttendance: AggregatedDriverAttendanceEntity,
      primaryCarId: PersistentId
    ): Promise<void> {
      await this.updateDriverAttendance(driverAttendance, [{ field: 'primaryCarId', value: primaryCarId }]);
      this.viewModel.onUpdateAllDriverAttendanceMap();
    },
    async onChangeForceRidePrimaryCar(
      driverAttendance: AggregatedDriverAttendanceEntity,
      value: boolean
    ): Promise<void> {
      await this.updateDriverAttendance(driverAttendance, [{ field: 'forceRidePrimaryCar', value }]);
      this.viewModel.onUpdateAllDriverAttendanceMap();
    },

    async onChangeRegularWorkPeriodString(
      driverAttendance: AggregatedDriverAttendanceEntity,
      regularWorkPeriodStartString: string,
      regularWorkPeriodEndString: string
    ): Promise<void> {
      await this.updateDriverAttendance(driverAttendance, [
        { field: 'regularWorkPeriodStart', value: hhMmToSecs(regularWorkPeriodStartString) },
        { field: 'regularWorkPeriodEnd', value: hhMmToSecs(regularWorkPeriodEndString) },
      ]);
      this.viewModel.onUpdateDriverAttendance(driverAttendance);
    },

    async onChangeRestPeriod(
      driverAttendance: AggregatedDriverAttendanceEntity,
      restPeriodStartString: Maybe<string>,
      restPeriodEndString: Maybe<string>
    ) {
      await this.updateDriverAttendance(driverAttendance, [
        { field: 'restPeriodStart', value: restPeriodStartString?.toSecs() },
        { field: 'restPeriodEnd', value: restPeriodEndString?.toSecs() },
      ]);
      this.viewModel.onUpdateDriverAttendance(driverAttendance);
    },

    async onChangeOvertimeWorkType(
      driverAttendance: AggregatedDriverAttendanceEntity,
      overtimeWorkType: OvertimeWorkType
    ): Promise<void> {
      const updateFields: UpdateField<UpdatableFields>[] = [];
      updateFields.push({ field: 'overtimeWorkType', value: overtimeWorkType });
      if (overtimeWorkType === OvertimeWorkType.None) {
        updateFields.push({ field: 'overtimeWorkableDuration', value: 0 });
      }
      await this.updateDriverAttendance(driverAttendance, updateFields);
      this.viewModel.onUpdateDriverAttendance(driverAttendance);
    },

    async onChangeOvertimeWorkableDuration(
      driverAttendance: AggregatedDriverAttendanceEntity,
      overtimeWorkableDuration: Seconds
    ): Promise<void> {
      await this.updateDriverAttendance(driverAttendance, [
        { field: 'overtimeWorkableDuration', value: overtimeWorkableDuration },
      ]);
      this.viewModel.onUpdateDriverAttendance(driverAttendance);
    },

    async updateDriverAttendance(
      driverAttendance: AggregatedDriverAttendanceEntity,
      diffs: UpdateField<UpdatableFields>[]
    ): Promise<AggregatedDriverAttendanceEntity> {
      const service = this.$context.applications.get(driverAttendanceSymbol);
      this.isManipulatingDriverAttendances = true;
      const updateData: IDriverAttendanceUpdateData = {
        id: driverAttendance.persistentId,
        attendanceId: driverAttendance.attendance.id,
        forceRidePrimaryCar: driverAttendance.forceRidePrimaryCar,
        driverId: driverAttendance.driver.id,
        primaryCarId: driverAttendance.primaryCar.id,
        templateName: driverAttendance.templateName,
        regularWorkPeriodStart: driverAttendance.regularWorkPeriodStart,
        regularWorkPeriodEnd: driverAttendance.regularWorkPeriodEnd,
        restPeriodStart: driverAttendance.restPeriodStart,
        restPeriodEnd: driverAttendance.restPeriodEnd,
        overtimeWorkType: driverAttendance.overtimeWorkType,
        overtimeWorkableDuration: driverAttendance.overtimeWorkableDuration,
      };
      for (const diff of diffs) {
        // any 若干気持ち悪いがキーである事は保証されている
        (updateData as any)[diff.field] = diff.value;
      }
      const entity = await service.update(driverAttendance.attendance.date, updateData);
      this.isManipulatingDriverAttendances = false;
      return entity;
    },

    getClassOf(day: IHeaderDayColumn): CssClasses {
      return {
        'r-driver-monthly-attendances-dialog__header-column': true,
        'r-driver-monthly-attendances-dialog__header-column--day': true,
        'r-driver-monthly-attendances-dialog__header-column--working-day': !day.isHoliday,
        'r-driver-monthly-attendances-dialog__header-column--holiday': day.isHoliday,
      };
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        Vue.nextTick(this.onCloseButtonClicked);
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.ArrowLeft)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.LEFT, {
          ...RinEventDialogComponentParam,
          [AdditionalInfoKeys.TARGET]: PageNames.DRIVER_ATTENDANCES,
        });
        Vue.nextTick(this.onClickPrevMonthButton);
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.ArrowRight)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.RIGHT, {
          ...RinEventDialogComponentParam,
          [AdditionalInfoKeys.TARGET]: PageNames.DRIVER_ATTENDANCES,
        });
        Vue.nextTick(this.onClickNextMonthButton);
      }
      context.stop();
    },
    async onCloseAndOpen({ driver, date }: { driver: AggregatedDriverEntity; date: Date }) {
      this.onCloseButtonClicked();
      await this.$context.events.openDriverMonthlyAttendancesFormEvent.emit({
        baseDate: date,
        // FIXME: これでいいのか？
        driverAttendances: this.viewModel.driverAttendances,
        driver,
      });
    },
  },
});

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4a61b880 = () => interopDefault(import('../src/pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _65e30205 = () => interopDefault(import('../src/pages/execution/index.vue' /* webpackChunkName: "pages/execution/index" */))
const _4a632e16 = () => interopDefault(import('../src/pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _70062b93 = () => interopDefault(import('../src/pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _3faa727e = () => interopDefault(import('../src/pages/masters/index.vue' /* webpackChunkName: "pages/masters/index" */))
const _cd049ffe = () => interopDefault(import('../src/pages/release-note/index.vue' /* webpackChunkName: "pages/release-note/index" */))
const _ce7b4e40 = () => interopDefault(import('../src/pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _128ce814 = () => interopDefault(import('../src/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _aff4988a = () => interopDefault(import('../src/pages/debug/navis.vue' /* webpackChunkName: "pages/debug/navis" */))
const _43a9ad54 = () => interopDefault(import('../src/pages/debug/test.vue' /* webpackChunkName: "pages/debug/test" */))
const _7d00171c = () => interopDefault(import('../src/pages/execution/packing-placements/index.vue' /* webpackChunkName: "pages/execution/packing-placements/index" */))
const _f6b4299c = () => interopDefault(import('../src/pages/masters/base-site/index.vue' /* webpackChunkName: "pages/masters/base-site/index" */))
const _802a57fa = () => interopDefault(import('../src/pages/masters/car/index.vue' /* webpackChunkName: "pages/masters/car/index" */))
const _5784cd82 = () => interopDefault(import('../src/pages/masters/check-item/index.vue' /* webpackChunkName: "pages/masters/check-item/index" */))
const _556a95ac = () => interopDefault(import('../src/pages/masters/collectable-period-template/index.vue' /* webpackChunkName: "pages/masters/collectable-period-template/index" */))
const _2d9ee520 = () => interopDefault(import('../src/pages/masters/container/index.vue' /* webpackChunkName: "pages/masters/container/index" */))
const _5513e35c = () => interopDefault(import('../src/pages/masters/disposal-site/index.vue' /* webpackChunkName: "pages/masters/disposal-site/index" */))
const _c338c2a2 = () => interopDefault(import('../src/pages/masters/driver/index.vue' /* webpackChunkName: "pages/masters/driver/index" */))
const _1a62f30b = () => interopDefault(import('../src/pages/masters/generation-site/index.vue' /* webpackChunkName: "pages/masters/generation-site/index" */))
const _4b4c9e06 = () => interopDefault(import('../src/pages/masters/office-setting/index.vue' /* webpackChunkName: "pages/masters/office-setting/index" */))
const _29a3e454 = () => interopDefault(import('../src/pages/masters/waste-type/index.vue' /* webpackChunkName: "pages/masters/waste-type/index" */))
const _77d08ed6 = () => interopDefault(import('../src/pages/schedule/carUsage.ts' /* webpackChunkName: "pages/schedule/carUsage" */))
const _1f896744 = () => interopDefault(import('../src/pages/schedule/inconsistency.ts' /* webpackChunkName: "pages/schedule/inconsistency" */))
const _45a59f17 = () => interopDefault(import('../src/pages/schedule/inconsistentRouteInfo.ts' /* webpackChunkName: "pages/schedule/inconsistentRouteInfo" */))
const _2b79c3db = () => interopDefault(import('../src/pages/schedule/infeasibility.ts' /* webpackChunkName: "pages/schedule/infeasibility" */))
const _abd32eec = () => interopDefault(import('../src/pages/schedule/orders/index.vue' /* webpackChunkName: "pages/schedule/orders/index" */))
const _6afefe2c = () => interopDefault(import('../src/pages/schedule/schedule.ts' /* webpackChunkName: "pages/schedule/schedule" */))
const _2553f6a2 = () => interopDefault(import('../src/pages/schedule/scheduleData.ts' /* webpackChunkName: "pages/schedule/scheduleData" */))
const _f4fcd9ee = () => interopDefault(import('../src/pages/schedule/scheduleDates.ts' /* webpackChunkName: "pages/schedule/scheduleDates" */))
const _1b8ef70c = () => interopDefault(import('../src/pages/schedule/scheduleSerializer.ts' /* webpackChunkName: "pages/schedule/scheduleSerializer" */))
const _3fada49b = () => interopDefault(import('../src/pages/masters/collectable-period-template/collectablePeriodTemplates.ts' /* webpackChunkName: "pages/masters/collectable-period-template/collectablePeriodTemplates" */))
const _386cdcf4 = () => interopDefault(import('../src/pages/masters/container/containerTasks.ts' /* webpackChunkName: "pages/masters/container/containerTasks" */))
const _4dff4efe = () => interopDefault(import('../src/pages/masters/driver/headerDateColumn.ts' /* webpackChunkName: "pages/masters/driver/headerDateColumn" */))
const _49e75ba4 = () => interopDefault(import('../src/pages/masters/generation-site/clients.ts' /* webpackChunkName: "pages/masters/generation-site/clients" */))
const _9596a150 = () => interopDefault(import('../src/pages/masters/generation-site/generationSites.ts' /* webpackChunkName: "pages/masters/generation-site/generationSites" */))
const _32f228dc = () => interopDefault(import('../src/pages/masters/generation-site/routingRegulation.ts' /* webpackChunkName: "pages/masters/generation-site/routingRegulation" */))
const _96bcb92c = () => interopDefault(import('../src/pages/masters/office-setting/officeSettings.ts' /* webpackChunkName: "pages/masters/office-setting/officeSettings" */))
const _49c5f780 = () => interopDefault(import('../src/pages/schedule/orders/searchOrders.ts' /* webpackChunkName: "pages/schedule/orders/searchOrders" */))
const _a2c62288 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/debug",
    component: _4a61b880,
    name: "debug"
  }, {
    path: "/execution",
    component: _65e30205,
    name: "execution"
  }, {
    path: "/login",
    component: _4a632e16,
    name: "login"
  }, {
    path: "/logout",
    component: _70062b93,
    name: "logout"
  }, {
    path: "/masters",
    component: _3faa727e,
    name: "masters"
  }, {
    path: "/release-note",
    component: _cd049ffe,
    name: "release-note"
  }, {
    path: "/schedule",
    component: _ce7b4e40,
    name: "schedule"
  }, {
    path: "/user",
    component: _128ce814,
    name: "user"
  }, {
    path: "/debug/navis",
    component: _aff4988a,
    name: "debug-navis"
  }, {
    path: "/debug/test",
    component: _43a9ad54,
    name: "debug-test"
  }, {
    path: "/execution/packing-placements",
    component: _7d00171c,
    name: "execution-packing-placements"
  }, {
    path: "/masters/base-site",
    component: _f6b4299c,
    name: "masters-base-site"
  }, {
    path: "/masters/car",
    component: _802a57fa,
    name: "masters-car"
  }, {
    path: "/masters/check-item",
    component: _5784cd82,
    name: "masters-check-item"
  }, {
    path: "/masters/collectable-period-template",
    component: _556a95ac,
    name: "masters-collectable-period-template"
  }, {
    path: "/masters/container",
    component: _2d9ee520,
    name: "masters-container"
  }, {
    path: "/masters/disposal-site",
    component: _5513e35c,
    name: "masters-disposal-site"
  }, {
    path: "/masters/driver",
    component: _c338c2a2,
    name: "masters-driver"
  }, {
    path: "/masters/generation-site",
    component: _1a62f30b,
    name: "masters-generation-site"
  }, {
    path: "/masters/office-setting",
    component: _4b4c9e06,
    name: "masters-office-setting"
  }, {
    path: "/masters/waste-type",
    component: _29a3e454,
    name: "masters-waste-type"
  }, {
    path: "/schedule/carUsage",
    component: _77d08ed6,
    name: "schedule-carUsage"
  }, {
    path: "/schedule/inconsistency",
    component: _1f896744,
    name: "schedule-inconsistency"
  }, {
    path: "/schedule/inconsistentRouteInfo",
    component: _45a59f17,
    name: "schedule-inconsistentRouteInfo"
  }, {
    path: "/schedule/infeasibility",
    component: _2b79c3db,
    name: "schedule-infeasibility"
  }, {
    path: "/schedule/orders",
    component: _abd32eec,
    name: "schedule-orders"
  }, {
    path: "/schedule/schedule",
    component: _6afefe2c,
    name: "schedule-schedule"
  }, {
    path: "/schedule/scheduleData",
    component: _2553f6a2,
    name: "schedule-scheduleData"
  }, {
    path: "/schedule/scheduleDates",
    component: _f4fcd9ee,
    name: "schedule-scheduleDates"
  }, {
    path: "/schedule/scheduleSerializer",
    component: _1b8ef70c,
    name: "schedule-scheduleSerializer"
  }, {
    path: "/masters/collectable-period-template/collectablePeriodTemplates",
    component: _3fada49b,
    name: "masters-collectable-period-template-collectablePeriodTemplates"
  }, {
    path: "/masters/container/containerTasks",
    component: _386cdcf4,
    name: "masters-container-containerTasks"
  }, {
    path: "/masters/driver/headerDateColumn",
    component: _4dff4efe,
    name: "masters-driver-headerDateColumn"
  }, {
    path: "/masters/generation-site/clients",
    component: _49e75ba4,
    name: "masters-generation-site-clients"
  }, {
    path: "/masters/generation-site/generationSites",
    component: _9596a150,
    name: "masters-generation-site-generationSites"
  }, {
    path: "/masters/generation-site/routingRegulation",
    component: _32f228dc,
    name: "masters-generation-site-routingRegulation"
  }, {
    path: "/masters/office-setting/officeSettings",
    component: _96bcb92c,
    name: "masters-office-setting-officeSettings"
  }, {
    path: "/schedule/orders/searchOrders",
    component: _49c5f780,
    name: "schedule-orders-searchOrders"
  }, {
    path: "/",
    component: _a2c62288,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { CreateDefaultMultipleDriversAttendancesPayload } from '~/framework/server-api/typeAliases';
import { IAttendanceData } from './attendance';
import { IDriverData } from './driver';
import { ICarData } from './car';

export const driverAttendance$createSymbol = Symbol('driverAttendance$createSymbol');
export const driverAttendance$createDefaultSymbol = Symbol('driverAttendance$createDefaultSymbol');
export const driverAttendance$deleteSymbol = Symbol('driverAttendance$deleteSymbol');
export const driverAttendance$getByDateRangeSymbol = Symbol('driverAttendance$getByDateRangeSymbol');
export const driverAttendance$getByDateRangeOfAllDriversSymbol = Symbol(
  'driverAttendance$getByDateRangeOfAllDriversSymbol'
);
export const driverAttendance$updateSymbol = Symbol('driverAttendance$updateSymbol');

export type DriverAttendance = {
  [driverAttendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [driverAttendance$getByDateRangeOfAllDriversSymbol]: IGetByDateRangeOfAllDriversApi;
  [driverAttendance$createSymbol]: ICreateDriverAttendanceApi;
  [driverAttendance$updateSymbol]: IUpdateApi;
  [driverAttendance$deleteSymbol]: IDeleteApi;
  [driverAttendance$createDefaultSymbol]: ICreateDefaultDriverAttendanceApi;
};

export interface ICreateDriverAttendanceApi {
  create(data: IDriverAttendanceCreateData[]): Promise<string[]>;
}

export interface ICreateDefaultDriverAttendanceApi {
  createDefault(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
  createDefaultMultiple(
    driverIds: string[],
    start: Date,
    end: Date
  ): Promise<CreateDefaultMultipleDriversAttendancesPayload>;
}

export interface IDeleteApi {
  delete(ids: string[]): Promise<string[]>;
}

export interface IDriverAttendanceData {
  id: string;
  attendance: IAttendanceData;
  driver: IDriverData;
  primaryCar: ICarData;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export interface IDriverAttendanceCreateData {
  attendanceId: string;
  driverId: string;
  primaryCarId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export interface IDriverAttendanceUpdateData extends IDriverAttendanceCreateData {
  id: string;
}

export interface IGetByDateRangeApi {
  getByDateRange(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IGetByDateRangeOfAllDriversApi {
  getByDateRangeOfAllDrivers(start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IUpdateApi {
  update(data: IDriverAttendanceUpdateData[]): Promise<string[]>;
}

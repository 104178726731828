import { Maybe } from '~/framework/typeAliases';
import { IInconsistentRouteInfo as IBaseInconsistentRouteInfo } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfo';
import { EntityIdGenerateFunctor } from '~/framework/systemContext';
import { IdGenerator } from '~/framework/core/id';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { Infeasibility } from '~/pages/schedule/infeasibility';

/**
 * こいつは UoW の管理下になく異質な存在なのだが、集約しているエンティティと言えばそうなのかもしれないのでここに置く
 * 直接的にデータの更新には関わらず、ここから IScheduleJsonObject をシリアライズする
 */
export interface IScheduleDataEntity<InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>> {
  /**
   * 識別子
   * ビューの更新にも利用される
   */
  id: string;

  /**
   * 固定のエラー
   */
  inconsistencies: Maybe<Inconsistency[]>;

  /**
   * マスター等のエラー
   */
  infeasibilities: Maybe<Infeasibility[]>;
}

/**
 * View 層のモデルでも対応可能な様にジェネリクスにしておく。
 *
 * R = IRouteEntity
 * O = IOriginalRouteEntity
 */
export class ScheduleDataEntity<InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>>
  implements IScheduleDataEntity<InconsistentRouteInfo>
{
  private readonly idGenerator: EntityIdGenerateFunctor = IdGenerator.generateNewId;

  id: string;
  inconsistencies: Maybe<Inconsistency[]>;
  infeasibilities: Maybe<Infeasibility[]>;

  constructor(id: string, inconsistencies: Maybe<Inconsistency[]>, infeasibilities: Maybe<Infeasibility[]>) {
    this.id = id;
    // V1ロジックをそのまま併用するために、空配列の場合はundefinedにする
    this.inconsistencies = inconsistencies && inconsistencies.length > 0 ? inconsistencies : undefined;
    this.infeasibilities = infeasibilities;
  }
}

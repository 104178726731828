import {
  IScheduleDataEntity,
  ScheduleDataEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDataEntity';
import { Maybe } from '~/framework/typeAliases';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';
import { PseudoOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { Infeasibility } from './infeasibility';

export interface IScheduleData extends IScheduleDataEntity<InconsistentRouteInfo> {
  /**
   * この配車表を読み込んだ段階で判明していた全乗務員
   * 配車表に関係ないデータも全部含んでいるのは人をまたいだ移動で最初に配車表に存在していなかった乗務員を
   * 割り当てる可能性があるため
   */
  readonly drivers: AggregatedDriverEntity[];

  /**
   * 全ての車
   */
  readonly cars: AggregatedCarEntity[];

  /**
   * ドライバー勤怠
   */
  readonly driverAttendances: AggregatedDriverAttendanceEntity[];

  /**
   * 全ての処分場
   */
  readonly disposals: AggregatedDisposalSiteEntity[];

  /**
   * 全ての受注
   */
  readonly orders: PseudoOrderEntity[];

  /**
   * エラーの数
   * 固定のエラーがあれば "?"
   * 作成不可のエラーがあれば "x"
   * なければ undefined
   */
  readonly errorNum: Maybe<string>;
}

export class ScheduleData extends ScheduleDataEntity<InconsistentRouteInfo> implements IScheduleData {
  readonly driverAttendances: AggregatedDriverAttendanceEntity[];
  readonly drivers: AggregatedDriverEntity[];
  readonly cars: AggregatedCarEntity[];
  readonly disposals: AggregatedDisposalSiteEntity[];
  readonly orders: PseudoOrderEntity[] = [];
  errorNum: Maybe<string> = undefined;

  constructor(
    id: string,
    inconsistencies: Maybe<Inconsistency[]>,
    infeasibilities: Maybe<Infeasibility[]>,
    drivers: AggregatedDriverEntity[],
    driverAttendances: AggregatedDriverAttendanceEntity[],
    cars: AggregatedCarEntity[],
    disposals: AggregatedDisposalSiteEntity[],
    orders: PseudoOrderEntity[]
  ) {
    super(id, inconsistencies, infeasibilities);
    this.drivers = drivers;
    this.driverAttendances = driverAttendances;
    this.cars = cars;
    this.disposals = disposals;
    this.orders = orders;
  }
}
